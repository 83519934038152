.artistRoom {
  fill: #e3e4e4;
  cursor: pointer;
}
.artistRoomClicked {
  fill: #e0f752;
}

.chasham-gala-2024-room {
  fill: #ffffff;
  cursor: pointer;
}
.chasham-gala-2024-room-clicked {
  fill: #e0f752;
}
