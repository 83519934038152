.view-ar-link {
  background-color: #333;
  padding: 12px;
  border-radius: 12px;
  width: fit-content;
  padding-right: 20px;
}

.homepage-container {
  background-color: var(--black100);
  height: 100%;
  text-align: left;
  padding-left: 4%;
  padding-right: 4%;
}

.social-media-btn-container {
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--white);
}

.homepage-heading {
  text-align: start;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  line-height: 28px;
  letter-spacing: -0.36px;
}

@media (min-width: 768px) {
  .homepage-container {
    padding-left: 16%;
    padding-right: 16%;
  }
}

.lime {
  color: var(--lime);
}

.white {
  color: var(--white);
}

.city-image {
  opacity: 0.56;
}

.city-names {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: -0.36px;
  color: var(--black);
}

.city-list-container {
  /* margin-top: 21px; */
  padding-top: 12rem;
  /* border-top: 1px solid #ffffff50; */
}

@media (max-width: 750px) {
  .city-list-container {
    padding-top: 200px;
  }
}

.no-of-cities {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgb(255, 255, 255, 0.5);
}

.event-image {
  border-radius: 9px 9px 0px 0px;
}

.br--9 {
  border-radius: 0px 0px 9px 9px;
}

.info-window-name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--black);
}

.event-cards-desktop {
  max-height: 185px;
  height: 100%;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  max-width: 600px;
}

.event-cards-desktop img {
  border-radius: 12px;
  min-height: 165px;
  max-height: 165px;
  min-width: 160px;
  max-width: 160px;
  object-fit: cover;
  background-color: var(--red100);
}

@media (max-width: 600px) {
}

.br--18 {
  border-radius: 18px;
}

.br--12 {
  border-radius: 12px;
}

.info-window-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  text-align: left;
  opacity: 0.5;
}

/* .city-names:hover {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.36px;
  color: #e0f752;
  opacity: 1;
} */
/* .city-names:active {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.36px;
  color: #e0f752;
  opacity: 1;
} */
.city-detail-container {
  padding: 0% !important;
  height: 100px;
  width: 300px;
}

.feature-detail-container {
  padding: 0% !important;
  height: 100px;
  width: 500px;
}

.city-background {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.15);
  align-items: center;
}

.edit-profile-btn {
  opacity: 0.5;
  /* White */
  font-weight: 600;
  font-size: 20px;
  line-height: 16.41px;
  border-radius: 19px;
  padding: 8px 22px;
  height: 32px;
  border: 1px solid var(--white);
  background: transparent;
  color: var(--white);
}

/* Radio button Css */
.tabs {
  list-style-type: none;
  display: flex;
  width: 100%;
  padding: 0;
}

@media (max-width: 768px) {
  .tabs {
    overflow-x: scroll;
  }

  .event-cards-desktop {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    max-height: 350px;
    /* TODO: AUTO Height adjust */
    height: 100%;
    min-height: 300px;
  }

  .event-cards-desktop img {
    border-radius: 12px;
    min-width: none;
    max-height: none;
    min-width: none;
    max-width: none;
    object-fit: cover;
  }
}

.tabs input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
}

.tabs label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  border-radius: 19px;
  padding: 8px 22px;
  /* height: 32px; */
  width: max-content;
  background-color: rgb(255, 255, 255, 0.32);
  color: var(--white);
  letter-spacing: -0.36px;
  white-space: nowrap;
}

.tabs input[type='radio']:checked + label {
  color: var(--blue);
  background-color: var(--lime);
}

.forward-arrow {
  width: 24px;
  margin-left: 4px;
  margin-top: 1px;
}

.horizontal-ruler {
  width: 100%;
  border-bottom: 1px solid var(--black);
}

@media (max-width: 550px) {
  .main {
    text-align: left;
    background-color: black;
    padding-bottom: 3vh;
    margin-top: 2rem;
  }
}

@media (min-width: 551px) {
  .main {
    box-shadow: 0px 0px 10px -4px #333;
    text-align: left;
    margin-bottom: 3vh;
    background-color: black;
    margin-top: 7rem;
    padding-bottom: 5vh;
    border-radius: 10px;
  }
}

.event {
  margin-top: 5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--paleBlue);
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: red !important;
  color: red !important;
}

.form {
  border-radius: 5px;
  background-color: transparent;
  padding: 16px;
  color: var(--white);
  width: 100%;
  height: 51px;
  border: 1px solid var(--gray100);
  cursor: text;
}

.form:focus {
  outline: 0;
  border-color: var(--lime);
  box-shadow: 0 0 0 0 var(--lime);
}

.form-repeat {
  font-weight: 600;
}

.date {
  border-radius: 3px;
  width: 100%;
  height: 50px;
  outline: none;
  border: 0px;
  cursor: auto;
  background-color: transparent;
  color: white;
}

.upload-img-btn:hover {
  border-color: var(--lime);
}

.upload-img-btn:active {
  border-color: var(--lime);
}

.upload-img-btn {
  width: 58px;
  height: 58px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border: 2px solid var(--white);
  border-radius: 8px;
  object-fit: contain;
}

.delete-icon {
  width: 19px;
  height: 19px;
  cursor: pointer;
  color: var(--white);
  position: absolute;
  margin-top: -8px;
  margin-left: 41px;
  background-color: red;
  border-radius: 15px;
}

/* .input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
  cursor: pointer;
} */

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  /* background-position: right;
    background-size: auto; */
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  /* filter: invert(1); */
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  filter: invert(1);
} */

@media (min-width: 460px) {
  .image-previewer {
    height: 280px;
    max-width: 320px;
    width: 100%;
    border: 2px dashed var(--white);
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
  }
}

@media (max-width: 460px) {
  .image-previewer {
    height: 280px;
    width: 100%;
    border: 2px dashed var(--white);
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
  }
}

.image-previewer img {
  object-fit: contain;
  border-radius: 8px;
}

.text_small {
  font-size: 13px;
}

.invalid-text {
  color: var(--pink-red);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* .sm_btn {
  width: 150px;
} */

@media (max-width: 460px) {
  .sm_btn {
    width: 100%;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.recaptcha div div div iframe {
  display: block !important;
}

html body div div iframe[title='recaptcha challenge expires in two minutes'] {
  display: block !important;
}

@media (min-width: 460px) {
  .width-md {
    width: 86%;
  }
}

.cities-fixed-top {
  position: fixed;
  top: 4.5rem;
  z-index: 100;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
  background-color: #121213;
}


.chashama2024Tab {
  list-style-type: none;
  display: flex;
  width: 100%;
  padding: 0;
}


@media (max-width: 768px) {
  .chashama2024Tab {
    overflow-x: scroll;
  }

  .event-cards-desktop {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    max-height: 350px;
    /* TODO: AUTO Height adjust */
    height: 100%;
    min-height: 300px;
  }

  .event-cards-desktop img {
    border-radius: 12px;
    min-width: none;
    max-height: none;
    min-width: none;
    max-width: none;
    object-fit: cover;
  }
}

  .chashama2024Tab input[type="radio"] {
      visibility: hidden;
      height: 0;
      width: 0;
    }

    .chashama2024Tab label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border-radius: 19px;
      padding: 8px 10px;
      width: max-content;
      background-color: rgba(255, 255, 255, 0.32);
      color: var(--white);
      letter-spacing: -0.36px;
      white-space: nowrap;
    }
    
    /* Styles for mobile screens (700px and below) */
    @media (max-width: 700px) {
      .chashama2024Tab label {
        font-size: 10px;
      }
    }
    
    .chashama2024Tab input[type="radio"]:checked + label {
      color: var(--blue);
      background-color: var(--lime);
    }