.carousel-back-button {
  position: absolute;
  left: 10px;
  bottom: 50%;
}
.event-name-text {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #ffffff;
  margin: 25px auto;
}
.event-series-text {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #e0f752;
}
.back-btn-chevron {
  margin-top: 5px;
}
@media (max-width: 768px) {
  .back-btn-chevron {
    position: fixed;
    top: 20px;
  }
  .carousel-back-button {
    display: none;
  }
  .carousel-forward-button {
    display: none;
  }
  .mobile-back-btn-for-art-event {
    position: fixed;
    top: 6rem;
    background-color: #000000;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 5px;
    z-index: 20;
  }
}

.carousel-forward-button {
  position: absolute;
  left: 96%;
  bottom: 50%;
}
.sidebar-btn {
  position: fixed;
  top: 55%;
  z-index: 1;
  background-color: black;
  border-radius: 50%;
  width: 32px;
  padding-bottom: 3px;
  padding-top: 2px;
  padding-right: 3px;
  height: 32px;
}

.discover-title {
  margin: 60px 0px 0px 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;
  color: #e0f752;
}

.discover-yes {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.discover-map-container {
  width: 395px;
  height: 376px;
  position: absolute;
  z-index: 10;
  top: 20%;
  left: 50%;
  background-color: black;
}

.discover-artwork-container {
  width: 395px;
  height: 376px;
  position: absolute;
  z-index: 10;
  top: 30%;
  left: 19%;
  background-color: black;
}
.art-event-map-and-list {
  width: 100%;
  overflow: auto;
}

.discover-container {
  width: 395px;
  height: 376px;
  background: #000000;
  justify-content: center;
}
.miami-art-week,
.chashama-gala,
.akumal-art-festival,
.link-by-stilllife,
.chashama-2024 {
  display: flex;
  overflow: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.link-by-NRVLD {
  width: 100%;
}

.artwork-container {
  padding: 15%;
  background: #e0f752;
  justify-content: center;
}

.hear-more {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height, or 108% */
  letter-spacing: -0.42px;
  color: #000000;
}
.event-map-container {
  height: 100%;
  width: 100%;
}
.hear-more-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #000000;
  /* add 3 dot after 12 line */
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 12;
  -moz-line-clamp: 12;
  -ms-line-clamp: 12;
  line-clamp: 12;
  overflow: hidden;
}

.event-desc-2-line {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.6px;
  color: #ffffff;
  /* add 3 dot after 12 line */
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.map-circle {
  background: #456bd9;
  border: 0.1875em solid #0f1c3f;
  border-radius: 50%;
  box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
  height: 5em;
  width: 5em;
}
.event-desc-1-line {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.7;
  /* add 3 dot after 12 line */
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.event-desc-1-line-chashama-2024 {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  opacity: 0.7;
  /* add 3 dot after 12 line */
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.discover-icon {
  text-align: end;
  margin: 15px;
}

.h-100vh {
  height: 100vh;
}

.bg-black {
  background: #000000;
}
.bg-light-black {
  background: #121212;
}

.bg-white {
  background: #ffffff;
}

.m-auto {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-50-responsive {
  width: 50%;
}
.ReactModal__Overlay--after-open {
  z-index: 10000000 !important;
}
.w-10 {
  width: 10%;
}
.w-5 {
  width: 1%;
}

.w-37 {
  width: 37%;
}
@media (min-width: 768px) {
  .w-37 {
    width: 45%;
    max-width: 550px;
    min-width: 470px;
  }
  .chashama-event {
    height: 100vh;
  }
  .link-by-stilllife {
    height: 100vh;
  }
  .link-by-NRVLD {
    height: 100vh;
  }
}

.w-67 {
  width: 71%;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt--13 {
  margin-top: -13px;
}

.ml-30 {
  margin-left: 30px;
}
.slideInner___2mfX9 {
  position: static !important;
  display: flex;
  justify-content: center;
}
.m-t-30 {
  margin-top: -30px !important;
}

.m-t-20 {
  margin-top: -20px !important;
}

.mt--21 {
  margin-top: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-27 {
  margin-right: 27px;
}

.mt--10 {
  margin-top: -10px;
}

.mr--15 {
  margin-right: -15px;
}

.font-weight-bold {
  font-weight: bold;
}
.dot-3-line-1 {
  /* add 3 dot after 2 line */
  line-height: 20px;
  /* word-break: break-all; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}
.dot-3 {
  /* add 3 dot after 2 line */
  line-height: 20px;
  /* word-break: break-all; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.margin-10 {
  margin: 10px;
}

.ta-end {
  text-align: end;
}

.ta-start {
  text-align: start;
}

.ta-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.f-size-12 {
  font-size: 12px !important;
}

.pr-29 {
  padding-right: 29px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-15 {
  padding-right: 15px;
}

.pad-15 {
  padding: 15px;
}

.mt-8 {
  margin-top: -8px;
}

.margin-5 {
  margin: 5px;
}

.postion-relative {
  position: relative;
}

.pad-all {
  padding: 2px 10px 0px 10px;
}

.display-box {
  display: -webkit-box;
}

.display-block {
  display: block;
}

.display-none-all {
  display: none;
}

.display-none {
  display: none;
}

.display-initial {
  display: -webkit-box;
}

.display-flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.display-direction-row {
  display: flex;
  flex-direction: row;
}

.display-direction-row_main {
  display: flex;
  flex-direction: row;
}

.display-direction-column {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .display-rows {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
  }
}

.overflow-auto {
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-auto::-webkit-scrollbar {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-y: hidden;
}

.h-100vh-map {
  height: 100vh;
}

.overflow-x-scroll {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}
.overflow-scroll {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
.color-black {
  color: black !important;
}

.h-60 {
  height: auto;
}
@media only screen and (min-width: 768px) {
  .h-60 {
    height: 105vh;
  }
}

.content-between {
  justify-content: space-between;
}

.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}

.font-weight-bold {
  font-weight: bold;
}

.horizontal-div {
  width: 57px;
  height: 4px;
  background: #e0f752;
  border-radius: 20px;
}

.show-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.show-scroll::-webkit-scrollbar {
  height: 10px !important;
  background-color: transparent;
}

.show-scroll::-webkit-scrollbar-thumb {
  background-color: #e0f752;
  border-radius: 50px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.row1 {
  display: flex;
}

.artwork-image-container {
  max-height: 90vh;
  height: 100%;
}

.no-events-show {
  position: absolute;

  margin-left: 33px;
}
.card-list-color-and-height {
  background-color: rgba(0, 0, 0);
}

@media (max-width: 767px) {
  .miami-art-week {
    width: 150%;
  }

  .display-rows {
    display: -webkit-box;
    overflow-y: hidden;
  }
  .card-sm {
    width: 80%;
  }
  .event-map-container {
    height: 60vh;
  }
  .chashama-gala {
    width: 130%;

    padding-top: 5vh;
  }
  .link-by-stilllife {
    width: 130%;
    padding: 0px;
  }
  .link-by-NRVLD {
    width: 100%;
    height: 60vh;
  }
  .akumal-art-festival {
    width: 200%;
  }
  .row1 {
    display: inline-grid;
  }
  .no-events-show {
    position: inherit;
    background-color: #000000;
    width: 100%;
    padding-bottom: 25px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .artwork-image-container {
    width: 100%;
    height: 100%;
  }

  .display-direction-row_main {
    flex-direction: column-reverse !important;
  }
  .xs-100 {
    width: 100% !important;
  }
  .discover-map-container {
    width: 0px !important;
    top: 30% !important;
    left: 4% !important;
    background-color: #212121 !important;
  }
  .discover-container {
    padding-top: 10px;
    width: 330px;
  }
  .h-100vh {
    height: 100%;
  }
  .h-100vh-map {
    height: 100vh;
    position: relative;
    animation-name: up;
    animation-duration: 1s;
  }

  .h-94vh-map {
    height: 87vh;
    position: relative;
    animation-name: bottom;
    animation-duration: 1s;
  }

  .mt--21 {
    margin-top: -21px !important;
  }

  .card-list-color-and-height {
    bottom: 0px;
    background: none;
    position: fixed;
    z-index: 1;
  }
  .map-and-list-height .card-list-color-and-height {
    bottom: 250px;
    background: none;
    position: relative;
    z-index: 1;
  }
  /*  iPhone 6/7/8 */

  .h-10-card-list {
    height: 10vh;
  }

  .w-50-responsive {
    width: 100%;
  }
  .artwork-container {
    width: 0px !important;
    height: 0px !important;
  }
  .display-none {
    display: -webkit-box;
  }
  .display-initial {
    display: none;
  }
  .lg-show {
    display: none !important;
  }
  .xs-show {
    display: -webkit-inline-box;
  }
  .ml-30 {
    margin-left: 0px;
  }
}

@media (min-width: 767px) {
  .xs-show {
    display: none !important;
  }
  .lg-show {
    display: block;
  }
}

.change-image {
  font-size: 1.25rem;
  font-family: 'New-York-Medium', sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 500px) {
  .loader-mobile {
    display: flex;
    align-items: center;
  }
}

.sidebar-fixed-top {
  position: fixed;
  top: 4rem;
  padding-top: 2rem;
  background-color: black;
  z-index: 1;
}

@media (max-width: 750px) {
  .sidebar-fixed-top {
    background-color: transparent !important;
  }
}

@media (min-width: 750px) {
  .z-3 {
    z-index: 3;
  }
}

.art-event-sidebar-fixed-top {
  position: fixed;
  top: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #000000;
  width: 30%;
  max-width: 550px;
  min-width: 470px;
  z-index: 10;
}

@media (max-width: 750px) {
  .art-event-sidebar-fixed-top {
    background-color: transparent !important;
  }
}

.chashama-2024-sidebar-fixed-top {
  position: fixed;
  top: 0;
  padding-top: 7px;
  padding-bottom: 2px;
  background-color: #000000;
  width: 30%;
  max-width: 550px;
  min-width: 470px;
  z-index: 10;
}

.chashama-2024 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  overflow: scroll;
}

@media (max-width: 750px) {
  /* .chashama-2024-sidebar-fixed-top {
    background-color: transparent !important;
  } */
  .chashama-2024-sidebar-fixed-top {
    background-color: black !important;
    width: 100%;
  }

  .chashama-2024 {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
    height: 60vh;
    overflow: scroll;
  }
}
