.chashma-gala-2024-room {
  fill: #ffffff;
  cursor: pointer;
}
.chashma-gala-2024-room-clicked {
  fill: #e0f752;
}
.svgText {
  font-size: 45px;
  fill: black;
  font-weight: 550;
}
